import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const EastBayAcupunctureLandingPage = () => (
  <PhysicianLandingPage
    physician="East Bay Acupuncture"
    institution="East Bay Acupuncture"
    referralCode="EASTBAYACU"
  />
)

export default EastBayAcupunctureLandingPage
